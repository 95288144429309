import { Outlet, useLoaderData } from '@remix-run/react';
import PageLayout from '@modules/professional/component/page-layout/PageLayout';
import type { DetailPageButtonsProps } from '@modules/business-manager/component/detail-page-buttons/DetailPageButtons';
import { DetailPageButtons } from '@modules/business-manager/component/detail-page-buttons/DetailPageButtons';
import type { ProfessionalDetailsInitialFragment } from 'generated/graphql';
import type { Branch } from '~/modules/enquiry/util/extract-branch-from-network.server';
import type { Breadcrumb } from '@modules/professional/type/details-root.types';
import type { DropdownItem } from '@modules/enquiry/component/enquiry-form/EnquiryForm';
import { useInView } from 'react-intersection-observer';
import { useSetEditMode } from '~/modules/root/hook/use-set-edit-mode';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import { getBMPagePath } from '@modules/root/util/get-bm-page-path';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import EnquiryModal from '~/modules/enquiry/component/enquiry-modal/EnquiryModal';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useState } from 'react';
import type { loader } from '@modules/professional/page/loaders/professional-page-loader.server';
import type { getPageLocalisation } from '~/modules/seo/api/get-page-localisation.server';

export interface ProfessionalDetailRootData {
    initial: ProfessionalDetailsInitialFragment;
    professionalBreadcrumb: Breadcrumb[];
    banners: ProfessionalBannersData;
    branchOptions: DropdownItem<Branch>[];
    detailButton: Omit<DetailPageButtonsProps, 'editLink'>;
    localisation: Awaited<ReturnType<typeof getPageLocalisation>>;
    primaryCTA: PrimaryCTAResult;
    warningMessages?: string[];
    shouldIncludeLocalBusinessSchema: boolean;
}

export type ProfessionalDetailRootLoaderData = typeof loader;

const ProfessionalDetailRootPage = () => {
    const { initial, detailButton, primaryCTA, awards, showEnquireModal } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const { mobile } = useAppDisplayModeContext();
    const [showEnquireModalLocal, setShowEnquireModalLocal] =
        useState(showEnquireModal);
    const editLink = getBMPagePath(initial.URLSegment);
    useSetEditMode(editLink, initial.ID);
    const [footerSectionRef, footerSectionInView] = useInView({
        threshold: 0.5,
    });

    return (
        <PageLayout primaryCTA={primaryCTA} awards={awards}>
            <DetailPageButtons
                showButton={!footerSectionInView}
                {...detailButton}
                editLink={editLink}
            />
            <Outlet />
            <EnquiryModal
                professional={{
                    ...initial,
                    CustomEnquiryMessage: initial.CustomEnquiryMessage ?? '',
                    Website: initial.Website ?? '',
                }}
                branchOptions={initial.branchOptions}
                open={showEnquireModalLocal}
                onOpenChange={(open) => {
                    setShowEnquireModalLocal(open);
                }}
                drawer={mobile}
            />
            <div ref={footerSectionRef} />
        </PageLayout>
    );
};

export default ProfessionalDetailRootPage;
